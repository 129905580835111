var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c('basic-form',{attrs:{"title":_vm.title,"isLoading":_vm.loading},on:{"save":_vm.save,"update:isLoading":function($event){_vm.loading=$event},"update:is-loading":function($event){_vm.loading=$event}}},[_c('template',{slot:"fields"},[_c('div',{staticClass:"flex flex-col md:flex-row justify-center"},[_c('div',{staticClass:"w-full md:w-1/2 mr-3"},[_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('first_name')
                ? _vm.errors.first('first_name')
                : '',"variant":_vm.showValidation && _vm.errors.first('first_name') ? 'danger' : '',"label":'Nombres'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:200'),expression:"'required|max:200'"}],attrs:{"data-vv-validate-on":"input","name":"first_name","placeholder":"Ingrese los nombres","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('first_name') ? 'danger' : ''},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('last_name')
                ? _vm.errors.first('last_name')
                : '',"variant":_vm.showValidation && _vm.errors.first('last_name') ? 'danger' : '',"label":'Apellidos'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"last_name","placeholder":"Ingrese los apellidos","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('last_name') ? 'danger' : ''},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('dni') ? _vm.errors.first('dni') : '',"variant":_vm.showValidation && _vm.errors.first('dni') ? 'danger' : '',"label":'Cédula de identidad / Acta de nacimiento'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('numeric'),expression:"'numeric'"}],attrs:{"data-vv-validate-on":"input","name":"dni","placeholder":"Ingrese el número de documento","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('dni') ? 'danger' : ''},model:{value:(_vm.form.dni),callback:function ($$v) {_vm.$set(_vm.form, "dni", $$v)},expression:"form.dni"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('birthdate')
                ? _vm.errors.first('birthdate')
                : '',"variant":_vm.showValidation && _vm.errors.first('birthdate') ? 'danger' : '',"label":'Fecha de nacimiento'}},[_c('t-datepicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"initial-view":"year","lang":"es","closeOnSelect":true,"data-vv-validate-on":"input","maxDate":new Date(),"name":"birthdate","id":"birthdate","placeholder":"Seleccione la fecha","disabled":_vm.loading,"variant":_vm.showValidation && _vm.errors.first('birthdate') ? 'danger' : ''},model:{value:(_vm.form.birthdate),callback:function ($$v) {_vm.$set(_vm.form, "birthdate", $$v)},expression:"form.birthdate"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('address')
                ? _vm.errors.first('address')
                : '',"variant":_vm.showValidation && _vm.errors.first('address') ? 'danger' : '',"label":'Dirección'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"address","placeholder":"Ingrese la dirección","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('address') ? 'danger' : ''},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1),_c('div',{staticClass:"flex flex-col md:flex-row items-end my-4"},[_c('t-input-group',{staticClass:"w-full md:w-1/2 md:ml-2 md:mr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('height')
                  ? _vm.errors.first('height')
                  : '',"variant":_vm.showValidation && _vm.errors.first('height') ? 'danger' : '',"label":'Estatura (en metros)'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|amount'),expression:"'required|amount'"}],attrs:{"data-vv-validate-on":"input","name":"height","placeholder":"Ingrese la altura","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('height') ? 'danger' : ''},model:{value:(_vm.form.height),callback:function ($$v) {_vm.$set(_vm.form, "height", $$v)},expression:"form.height"}})],1),_c('t-input-group',{staticClass:"w-full md:w-1/2 md:ml-2 md:mr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('weight')
                  ? _vm.errors.first('weight')
                  : '',"variant":_vm.showValidation && _vm.errors.first('weight') ? 'danger' : '',"label":'Peso (en kilogramos)'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|amount'),expression:"'required|amount'"}],attrs:{"data-vv-validate-on":"input","name":"weight","placeholder":"Ingrese el peso","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('weight') ? 'danger' : ''},model:{value:(_vm.form.weight),callback:function ($$v) {_vm.$set(_vm.form, "weight", $$v)},expression:"form.weight"}})],1)],1),_c('div',{staticClass:"flex justify-start my-4"},[_c('p',[_vm._v("Mostrar en la app")]),_c('t-toggle',{staticClass:"mx-3",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.toggleVisibility(!_vm.visible)}},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}}),_c('p',[_vm._v("Sí")])],1),_c('t-input-group',{staticClass:"w-full md:w-full md:mr-0 md:pr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('url_dni_picture')
                ? _vm.errors.first('url_dni_picture')
                : '',"variant":_vm.showValidation && _vm.errors.first('url_dni_picture')
                ? 'danger'
                : '',"label":'Foto cédula / Acta de nacimiento'}},[_c('t-upload-file',{attrs:{"disabled":_vm.loading,"file":_vm.form.url_dni_picture,"externalUrl":typeof _vm.form.url_dni_picture === 'string'
                  ? _vm.form.url_dni_picture
                  : '',"backgroundColor":"bg-white","placeholder":"Selecciona una imagen","height":"250px","accept":"image/*"},on:{"update:file":function($event){return _vm.$set(_vm.form, "url_dni_picture", $event)}}})],1)],1),_c('div',{staticClass:"w-full md:w-1/2 mx-1 relative"},[_c('div',{staticClass:"flex flex-col md:flex-row items-end justify-around my-2"},[_c('t-input-group',{staticClass:"w-full md:w-1/2 md:mr-0 md:pr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('url_profile_picture')
                  ? _vm.errors.first('url_profile_picture')
                  : '',"variant":_vm.showValidation && _vm.errors.first('url_profile_picture')
                  ? 'danger'
                  : '',"label":'Foto del jugador'}},[_c('cropper-upload',{attrs:{"file":_vm.form.url_profile_picture,"externalUrl":typeof _vm.form.url_profile_picture === 'string'
                    ? _vm.form.url_profile_picture
                    : null,"title":"Foto del jugador","aspectRatio":3/4,"height":"250px","backgroundColor":"bg-white"},on:{"update:file":function($event){return _vm.$set(_vm.form, "url_profile_picture", $event)}}})],1),_c('t-input-group',{staticClass:"w-full md:w-1/2 md:mr-0 md:pr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('url_celebration_picture')
                  ? _vm.errors.first('url_celebration_picture')
                  : '',"variant":_vm.showValidation && _vm.errors.first('url_celebration_picture')
                  ? 'danger'
                  : ''}},[_c('label',{staticClass:"block text-left font-bold"},[_vm._v("Foto del jugador (Celebración)")]),_c('cropper-upload',{attrs:{"file":_vm.form.photos[0].url_photo,"externalUrl":typeof _vm.form.photos[0].url_photo === 'string'
                    ? _vm.form.photos[0].url_photo
                    : null,"title":"Foto de celebración","aspectRatio":3/4,"height":"250px","backgroundColor":"bg-white"},on:{"update:file":function($event){return _vm.$set(_vm.form.photos[0], "url_photo", $event)}}})],1)],1),_c('div',{staticClass:"flex flex-col md:flex-row items-end my-4"},[_c('t-input-group',{staticClass:"w-full md:w-1/2 md:ml-2 md:mr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('id_category')
                  ? _vm.errors.first('id_category')
                  : '',"variant":_vm.showValidation && _vm.errors.first('id_category') ? 'danger' : '',"label":'Categoría'}},[_c('t-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"id_category","placeholder":"Seleccione una opción","disabled":_vm.loading,"textAttribute":"name","valueAttribute":"id","options":_vm.categories,"variant":_vm.showValidation && _vm.errors.first('id_category')
                    ? 'danger'
                    : ''},model:{value:(_vm.form.id_category),callback:function ($$v) {_vm.$set(_vm.form, "id_category", $$v)},expression:"form.id_category"}})],1),_c('t-input-group',{staticClass:"w-full md:w-1/2 md:ml-2 md:mr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('id_subcategory')
                  ? _vm.errors.first('id_subcategory')
                  : '',"variant":_vm.showValidation && _vm.errors.first('id_subcategory')
                  ? 'danger'
                  : '',"label":'Subcategoría'}},[_c('t-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"id_subcategory","placeholder":"Seleccione una opción","disabled":_vm.loading,"textAttribute":"name","valueAttribute":"id","options":_vm.subcategories,"variant":_vm.showValidation && _vm.errors.first('id_subcategory')
                    ? 'danger'
                    : ''},model:{value:(_vm.form.id_subcategory),callback:function ($$v) {_vm.$set(_vm.form, "id_subcategory", $$v)},expression:"form.id_subcategory"}})],1)],1),_c('div',{staticClass:"flex flex-col md:flex-row items-end my-4"},[_c('t-input-group',{staticClass:"w-full md:w-1/2 md:ml-2 md:mr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('gender')
                  ? _vm.errors.first('gender')
                  : '',"variant":_vm.showValidation && _vm.errors.first('gender') ? 'danger' : '',"label":'Sexo'}},[_c('t-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"gender","placeholder":"Seleccione una opción","disabled":_vm.loading,"textAttribute":"name","valueAttribute":"id","options":_vm.genders,"variant":_vm.showValidation && _vm.errors.first('gender') ? 'danger' : ''},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}})],1),_c('t-input-group',{staticClass:"w-full md:w-1/2 md:ml-2 md:mr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('id_academy')
                  ? _vm.errors.first('id_academy')
                  : '',"variant":_vm.showValidation && _vm.errors.first('id_academy') ? 'danger' : '',"label":'Equipo'}},[_c('t-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"id_academy","placeholder":"Seleccione una opción","disabled":_vm.loading,"textAttribute":"name","valueAttribute":"id","options":_vm.academies,"variant":_vm.showValidation && _vm.errors.first('id_academy') ? 'danger' : ''},model:{value:(_vm.form.id_academy),callback:function ($$v) {_vm.$set(_vm.form, "id_academy", $$v)},expression:"form.id_academy"}})],1)],1),_c('div',{staticClass:"flex flex-col md:flex-row items-end my-4"},[_c('t-input-group',{staticClass:"w-full md:w-1/2 md:ml-2 md:mr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('position')
                  ? _vm.errors.first('position')
                  : '',"variant":_vm.showValidation && _vm.errors.first('position') ? 'danger' : '',"label":'Posición'}},[_c('t-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"position","placeholder":"Seleccione una opción","disabled":_vm.loading,"textAttribute":"name","valueAttribute":"id","options":_vm.positions,"variant":_vm.showValidation && _vm.errors.first('position') ? 'danger' : ''},model:{value:(_vm.form.position),callback:function ($$v) {_vm.$set(_vm.form, "position", $$v)},expression:"form.position"}})],1),_c('t-input-group',{staticClass:"w-full md:w-1/2 md:ml-2 md:mr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('favorite_player')
                  ? _vm.errors.first('favorite_player')
                  : '',"variant":_vm.showValidation && _vm.errors.first('favorite_player')
                  ? 'danger'
                  : '',"label":'Jugador favorito'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"favorite_player","placeholder":"Ingrese el nombre del jugador favorito","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('favorite_player')
                    ? 'danger'
                    : ''},model:{value:(_vm.form.favorite_player),callback:function ($$v) {_vm.$set(_vm.form, "favorite_player", $$v)},expression:"form.favorite_player"}})],1)],1),_c('div',{staticClass:"flex flex-col md:flex-row items-end my-4"},[_c('t-input-group',{staticClass:"w-full md:w-1/2 md:ml-2 md:mr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('skillful_foot')
                  ? _vm.errors.first('skillful_foot')
                  : '',"variant":_vm.showValidation && _vm.errors.first('skillful_foot')
                  ? 'danger'
                  : '',"label":'Pie dominante'}},[_c('t-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"skillful_foot","placeholder":"Seleccione una opción","disabled":_vm.loading,"textAttribute":"name","valueAttribute":"id","options":[
                  {
                    id: 'Izquierdo',
                    name: 'Izquierdo'
                  },
                  {
                    id: 'Derecho',
                    name: 'Derecho'
                  }
                ],"variant":_vm.showValidation && _vm.errors.first('skillful_foot')
                    ? 'danger'
                    : ''},model:{value:(_vm.form.skillful_foot),callback:function ($$v) {_vm.$set(_vm.form, "skillful_foot", $$v)},expression:"form.skillful_foot"}})],1),_c('t-input-group',{staticClass:"w-full md:w-1/2 md:ml-2 md:mr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('favorite_team')
                  ? _vm.errors.first('favorite_team')
                  : '',"variant":_vm.showValidation && _vm.errors.first('favorite_team')
                  ? 'danger'
                  : '',"label":'Equipo favorito'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"favorite_team","placeholder":"Ingrese el nombre del equipo favorito","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('favorite_team')
                    ? 'danger'
                    : ''},model:{value:(_vm.form.favorite_team),callback:function ($$v) {_vm.$set(_vm.form, "favorite_team", $$v)},expression:"form.favorite_team"}})],1)],1),_c('div',{staticClass:"flex flex-col md:flex-row items-end my-4"},[_c('t-input-group',{staticClass:"w-full md:w-1/2 md:ml-2 md:mr-2",attrs:{"feedback":_vm.showValidation && _vm.errors.first('jersey_number')
                  ? _vm.errors.first('jersey_number')
                  : '',"variant":_vm.showValidation && _vm.errors.first('jersey_number')
                  ? 'danger'
                  : '',"label":'Número de camisa'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:0|max:99'),expression:"'required|min:0|max:99'"}],attrs:{"data-vv-validate-on":"input","name":"jersey_number","placeholder":"Ingrese número del 1 al 99","autocomplete":"off","disabled":_vm.loading,"type":"number","variant":_vm.showValidation && _vm.errors.first('jersey_number')
                    ? 'danger'
                    : ''},model:{value:(_vm.form.jersey_number),callback:function ($$v) {_vm.$set(_vm.form, "jersey_number", $$v)},expression:"form.jersey_number"}})],1)],1),(_vm.deletable)?_c('div',{staticClass:"absolute bottom-0 w-full flex justify-center"},[_c('t-button',{attrs:{"type":"button","variant":"error"},on:{"click":_vm.deleteElement}},[_vm._v("Eliminar jugador")])],1):_vm._e()])])])],2),_c('t-card',{staticClass:"w-full",attrs:{"variant":"form"}},[_c('div',{staticClass:"flex justify-between px-3 bg-white py-4 rounded-t-md mt-3"},[_c('div',{staticClass:"flex items-center"},[_c('p',{staticClass:"uppercase font-bold text-sm my-auto text-center mr-2"},[_vm._v(" Datos de los representantes ")]),_c('icon',{staticClass:"rounded-full bg-red-1100 text-white text-3xl p-2 my-auto",staticStyle:{"width":"26px","height":"26px","cursor":"pointer"},attrs:{"icon":"plus"},on:{"click":_vm.addRepresentative}})],1)]),_vm._l((_vm.form.representatives),function(representative,index){return _c('div',{key:representative.key,staticClass:"flex flex-col px-4 bg-gray-100 pb-2"},[_c('div',{staticClass:"flex items-center justify-end mt-3"},[_c('t-button',{staticClass:"mr-3",attrs:{"type":"button","variant":"error"},on:{"click":function($event){return _vm.deleteRepresentative(index)}}},[_vm._v("Eliminar representante")]),(_vm.deletable)?_c('t-button',{attrs:{"variant":"warning","type":"button"},on:{"click":function($event){return _vm.saveRepresentative(index)}}},[_vm._v(" Guardar cambios "),(_vm.loadingRepresentatives)?_c('t-loader',{attrs:{"extraClass":"ml-2"}}):_vm._e()],1):_vm._e()],1),_c('div',{staticClass:"flex justify-center pb-4",class:_vm.form.representatives[index + 1] ? 'border-gray-300 border-b-2' : ''},[_c('div',{staticClass:"w-1/2 md:px-5"},[_c('t-input-group',{staticClass:"w-full md:w-1/2 md:ml-2 md:mr-2",attrs:{"feedback":_vm.showValidation &&
              _vm.errors.first(("representative_" + (representative.key) + "_type"))
                ? _vm.errors.first(("representative_" + (representative.key) + "_type"))
                : '',"variant":_vm.showValidation &&
              _vm.errors.first(("representative_" + (representative.key) + "_type"))
                ? 'danger'
                : '',"label":'Tipo'}},[_c('t-select',{directives:[{name:"validate",rawName:"v-validate",value:('simple_required'),expression:"'simple_required'"}],attrs:{"data-vv-validate-on":"input","name":("representative_" + (representative.key) + "_type"),"placeholder":"Seleccione un tipo","disabled":_vm.loadingRepresentatives,"textAttribute":"name","valueAttribute":"id","options":_vm.representativeOptions(),"variant":_vm.showValidation &&
                _vm.errors.first(("representative_" + (representative.key) + "_type"))
                  ? 'danger'
                  : ''},model:{value:(representative.type),callback:function ($$v) {_vm.$set(representative, "type", $$v)},expression:"representative.type"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation &&
              _vm.errors.first(("representative_" + (representative.key) + "_first_name"))
                ? _vm.errors.first(
                    ("representative_" + (representative.key) + "_first_name")
                  )
                : '',"variant":_vm.showValidation &&
              _vm.errors.first(("representative_" + (representative.key) + "_first_name"))
                ? 'danger'
                : '',"label":'Nombres'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('simple_required|max:200'),expression:"'simple_required|max:200'"}],attrs:{"data-vv-validate-on":"input","name":("representative_" + (representative.key) + "_first_name"),"placeholder":"Ingrese los nombres","autocomplete":"off","disabled":_vm.loadingRepresentatives,"type":"text","variant":_vm.showValidation &&
                _vm.errors.first(
                  ("representative_" + (representative.key) + "_first_name")
                )
                  ? 'danger'
                  : ''},model:{value:(representative.first_name),callback:function ($$v) {_vm.$set(representative, "first_name", $$v)},expression:"representative.first_name"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation &&
              _vm.errors.first(("representative_" + (representative.key) + "_last_name"))
                ? _vm.errors.first(
                    ("representative_" + (representative.key) + "_last_name")
                  )
                : '',"variant":_vm.showValidation &&
              _vm.errors.first(("representative_" + (representative.key) + "_last_name"))
                ? 'danger'
                : '',"label":'Apellidos'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('simple_required|max:200'),expression:"'simple_required|max:200'"}],attrs:{"data-vv-validate-on":"input","name":("representative_" + (representative.key) + "_last_name"),"placeholder":"Ingrese los apellidos","autocomplete":"off","disabled":_vm.loadingRepresentatives,"type":"text","variant":_vm.showValidation &&
                _vm.errors.first(("representative_" + (representative.key) + "_last_name"))
                  ? 'danger'
                  : ''},model:{value:(representative.last_name),callback:function ($$v) {_vm.$set(representative, "last_name", $$v)},expression:"representative.last_name"}})],1),_c('t-input-group',{staticClass:"w-full md:w-1/2 md:mr-2 md:ml-2",attrs:{"feedback":_vm.showValidation &&
              _vm.errors.first(("representative_" + (representative.key) + "_dni"))
                ? _vm.errors.first(("representative_" + (representative.key) + "_dni"))
                : '',"variant":_vm.showValidation &&
              _vm.errors.first(("representative_" + (representative.key) + "_dni"))
                ? 'danger'
                : '',"label":'Cédula de identidad'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('simple_required'),expression:"'simple_required'"}],attrs:{"data-vv-validate-on":"input","name":("representative_" + (representative.key) + "_dni"),"placeholder":"Ingrese el número de cedula de identidad","autocomplete":"off","disabled":_vm.loadingRepresentatives,"type":"text","variant":_vm.showValidation &&
                _vm.errors.first(("representative_" + (representative.key) + "_dni"))
                  ? 'danger'
                  : ''},model:{value:(representative.dni),callback:function ($$v) {_vm.$set(representative, "dni", $$v)},expression:"representative.dni"}})],1),_c('t-input-group',{staticClass:"w-full md:w-1/2 md:mr-0 md:pr-2",attrs:{"label":'Foto de la cédula'}},[_c('t-upload-file',{attrs:{"disabled":_vm.loading,"file":representative.url_dni_picture,"externalUrl":typeof representative.url_dni_picture === 'string'
                  ? representative.url_dni_picture
                  : '',"backgroundColor":"bg-white","placeholder":"Selecciona una imagen","height":"250px","accept":"image/*"},on:{"update:file":function($event){return _vm.$set(representative, "url_dni_picture", $event)}}})],1)],1),_c('div',{staticClass:"w-1/2 md:px-5"},[_c('div',{staticClass:"flex flex-col md:flex-row items-end my-4"},[_c('t-input-group',{staticClass:"w-full md:w-1/2 md:mr-2 md:ml-2",attrs:{"feedback":_vm.showValidation &&
                _vm.errors.first(("representative_" + (representative.key) + "_phone"))
                  ? _vm.errors.first(("representative_" + (representative.key) + "_phone"))
                  : '',"variant":_vm.showValidation &&
                _vm.errors.first(("representative_" + (representative.key) + "_phone"))
                  ? 'danger'
                  : '',"label":'Celular'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('phone'),expression:"'phone'"}],attrs:{"data-vv-validate-on":"input","name":("representative_" + (representative.key) + "_phone"),"placeholder":"Ingrese el teléfono celular","autocomplete":"off","disabled":_vm.loadingRepresentatives,"type":"text","variant":_vm.showValidation &&
                  _vm.errors.first(("representative_" + (representative.key) + "_phone"))
                    ? 'danger'
                    : ''},model:{value:(representative.phone),callback:function ($$v) {_vm.$set(representative, "phone", $$v)},expression:"representative.phone"}})],1),_c('t-input-group',{staticClass:"w-full md:w-1/2 md:ml-2",attrs:{"feedback":_vm.showValidation &&
                _vm.errors.first(
                  ("representative_" + (representative.key) + "_local_phone")
                )
                  ? _vm.errors.first(
                      ("representative_" + (representative.key) + "_local_phone")
                    )
                  : '',"variant":_vm.showValidation &&
                _vm.errors.first(
                  ("representative_" + (representative.key) + "_local_phone")
                )
                  ? 'danger'
                  : '',"label":'Otro teléfono'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('phone'),expression:"'phone'"}],attrs:{"data-vv-validate-on":"input","name":("representative_" + (representative.key) + "_local_phone"),"placeholder":"Ingrese otro número de teléfono","autocomplete":"off","disabled":_vm.loadingRepresentatives,"type":"text","variant":_vm.showValidation &&
                  _vm.errors.first(
                    ("representative_" + (representative.key) + "_local_phone")
                  )
                    ? 'danger'
                    : ''},model:{value:(representative.local_phone),callback:function ($$v) {_vm.$set(representative, "local_phone", $$v)},expression:"representative.local_phone"}})],1)],1),_c('t-input-group',{staticClass:"w-full md:mr-2 md:ml-2",attrs:{"feedback":_vm.showValidation &&
              _vm.errors.first(("representative_" + (representative.key) + "_email"))
                ? _vm.errors.first(("representative_" + (representative.key) + "_email"))
                : '',"variant":_vm.showValidation &&
              _vm.errors.first(("representative_" + (representative.key) + "_email"))
                ? 'danger'
                : '',"label":'Correo electrónico'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('simple_required|simple_email'),expression:"'simple_required|simple_email'"}],attrs:{"data-vv-validate-on":"input","name":("representative_" + (representative.key) + "_email"),"placeholder":"Ingrese el correo electrónico","autocomplete":"off","disabled":_vm.loadingRepresentatives,"type":"text","variant":_vm.showValidation &&
                _vm.errors.first(("representative_" + (representative.key) + "_email"))
                  ? 'danger'
                  : ''},model:{value:(representative.email),callback:function ($$v) {_vm.$set(representative, "email", $$v)},expression:"representative.email"}})],1),_c('t-input-group',{staticClass:"w-full md:mr-2 md:ml-2",attrs:{"feedback":_vm.showValidation &&
              _vm.errors.first(("representative_" + (representative.key) + "_address"))
                ? _vm.errors.first(("representative_" + (representative.key) + "_address"))
                : '',"variant":_vm.showValidation &&
              _vm.errors.first(("representative_" + (representative.key) + "_address"))
                ? 'danger'
                : '',"label":'Dirección'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('simple_required'),expression:"'simple_required'"}],attrs:{"data-vv-validate-on":"input","name":("representative_" + (representative.key) + "_address"),"placeholder":"Ingrese la dirección","autocomplete":"off","disabled":_vm.loadingRepresentatives,"type":"text","variant":_vm.showValidation &&
                _vm.errors.first(("representative_" + (representative.key) + "_address"))
                  ? 'danger'
                  : ''},model:{value:(representative.address),callback:function ($$v) {_vm.$set(representative, "address", $$v)},expression:"representative.address"}})],1),_c('t-input-group',{staticClass:"w-full md:mr-2 md:ml-2",attrs:{"feedback":_vm.showValidation &&
              _vm.errors.first(("representative_" + (representative.key) + "_workplace"))
                ? _vm.errors.first(
                    ("representative_" + (representative.key) + "_workplace")
                  )
                : '',"variant":_vm.showValidation &&
              _vm.errors.first(("representative_" + (representative.key) + "_workplace"))
                ? 'danger'
                : '',"label":'Empresa donde labora'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('simple_required'),expression:"'simple_required'"}],attrs:{"data-vv-validate-on":"input","name":("representative_" + (representative.key) + "_workplace"),"placeholder":"Ingrese el nombre de la empresa","autocomplete":"off","disabled":_vm.loadingRepresentatives,"type":"text","variant":_vm.showValidation &&
                _vm.errors.first(("representative_" + (representative.key) + "_workplace"))
                  ? 'danger'
                  : ''},model:{value:(representative.workplace),callback:function ($$v) {_vm.$set(representative, "workplace", $$v)},expression:"representative.workplace"}})],1),_c('t-input-group',{staticClass:"w-full md:mr-2 md:ml-2",attrs:{"feedback":_vm.showValidation &&
              _vm.errors.first(("representative_" + (representative.key) + "_profession"))
                ? _vm.errors.first(
                    ("representative_" + (representative.key) + "_profession")
                  )
                : '',"variant":_vm.showValidation &&
              _vm.errors.first(("representative_" + (representative.key) + "_profession"))
                ? 'danger'
                : '',"label":'Cargo que ocupa'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('simple_required'),expression:"'simple_required'"}],attrs:{"data-vv-validate-on":"input","name":("representative_" + (representative.key) + "_profession"),"placeholder":"Ingrese el cargo que ocupa","autocomplete":"off","disabled":_vm.loadingRepresentatives,"type":"text","variant":_vm.showValidation &&
                _vm.errors.first(
                  ("representative_" + (representative.key) + "_profession")
                )
                  ? 'danger'
                  : ''},model:{value:(representative.profession),callback:function ($$v) {_vm.$set(representative, "profession", $$v)},expression:"representative.profession"}})],1)],1)])])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }